
import React, { useState } from "react";
import { Container, Row, Col, Card, Button, Form, Image, Modal } from "react-bootstrap";
import { Switch } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import "bootstrap/dist/css/bootstrap.min.css";
import "./membercss/MemberInfo.css";
import "./membercss/InstructorSetting.css";
import Avatars from './Avatars';
import useTranslations from '../transalation/TranslationUtils';
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { initSimpleData, setSimpleData, setSimpleDataToMemberData, updateInstructor, uploadAvatarImage } from "app/redux/slices/rdxMemberSlice";
function InstructorSetting() {
    const dispatch = useDispatch();
    const { instructorSetting, instructorQualification,
        addInstructorPhoto, changeInstructorPhoto, bio,
        instructorQualificationEn, bioEn, showErrMsg,
        photoNotUpdateHint,
        savechaneg, cancel } = useTranslations();

    const memberEditData = useSelector((state) => state.rdxMember?.memberEditData || {});
    const editInstructor = useSelector((state) => state.rdxMember?.editInstructor || {});

    const [isSuccess, setIsSuccess] = useState(false);
    const [resultMsg, setResultMsg] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);

    useEffect(() => {
        let data = {
            data: { ...memberEditData.instructor, "account_id": memberEditData.account_id },
            projection: "editInstructor",
            type: "obj"
        };
        dispatch(initSimpleData(data));
    }, []);

    const handleSwitchChange = (e) => {
        let data = {
            data: !editInstructor.is_instructor,
            projection: "editInstructor",
            path: "is_instructor"
        }
        dispatch(setSimpleData(data));
    }

    const handleChange = (e, path) => {
        const { name, value } = e.target;

        let data = {
            data: value,
            projection: "editInstructor",
            path: path
        }
        dispatch(setSimpleData(data));
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        // console.log(file);
        setSelectedFile(file);
        setPreviewImage(URL.createObjectURL(file));
    };

    const handleOpen = () => setShowModal(true);

    const handleClose = async () => {
        setSelectedFile(null);
        setPreviewImage(null);
        setShowModal(false);
    }

    const handleModalSave = () => {
        if (selectedFile) {
            let data = {
                data: previewImage,
                projection: "editInstructor",
                path: "instructor_avatar"
            }
            dispatch(setSimpleData(data));

            setShowModal(false);
        } else {
            alert("Please select an image.");
        }
    };

    const handleSave = async () => {
        if (!editInstructor.instructor_qualification?.en ||
            !editInstructor.instructor_qualification?.zh ||
            !editInstructor.instructor_bio?.en ||
            !editInstructor.instructor_bio?.zh) {
            alert("All fields are required");
            return;
        }

        let instructorCallback = (isSuccess, data) => {
            setIsSuccess(isSuccess);
            if (isSuccess) {
                setSelectedFile(null);
                setPreviewImage(null);
                let rData = {
                    data: editInstructor,
                    projection: "instructor",
                    base: memberEditData.instructor
                };
                dispatch(setSimpleDataToMemberData(rData));
                setResultMsg("Instructor Setting update successfully!");
            } else {
                setResultMsg(showErrMsg(data));
            }
        }

        if (selectedFile) {
            dispatch(uploadAvatarImage(memberEditData.account_id, selectedFile, (imgUrl, msg) => {
                if (imgUrl) {
                    //upload 成功 且 s3 image url 即時更新
                    // let data = {
                    //     data: imgUrl,
                    //     projection: "editInstructor",
                    //     path: "instructor_avatar"
                    // }

                    // dispatch((dispatch, getState) => {
                    //     dispatch(setSimpleData(data));

                    //     const latestEditInsturctor = getState().rdxMember.editInstructor;
                    //     dispatch(updateInstructor(latestEditInsturctor, instructorCallback));
                    // });


                    //(目前狀況)upload 成功 但 s3 image url 404(要隔一陣子 preview image url 才會生效)
                    let sendData = {
                        ...editInstructor,
                        "instructor_avatar": imgUrl
                    };
                    dispatch(updateInstructor(sendData, instructorCallback));
                } else {
                    setResultMsg(msg);
                }
            }));
        } else {
            dispatch(updateInstructor(editInstructor, instructorCallback));
        }
    };

    const handleSelectFile = () => {
        document.getElementById("file-input").click();
    };

    return (
        <Container fluid>
            <Row>
                <Col md={12}>
                    <Card className="mt-0 border-0" style={{ position: "relative" }}>
                        <Card.Body>
                            <Avatars initAvatar={memberEditData["account_avatar"]} />
                            <br />
                            <Row className="align-items-center">
                                <Col>
                                    <h5 className="basicinfo mt-3">
                                        {instructorSetting()}
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={editInstructor.is_instructor}
                                                    onChange={handleSwitchChange}
                                                    color="primary"
                                                    className="ms-3"
                                                />
                                            }
                                            label=""
                                        />
                                    </h5>
                                </Col>
                            </Row>
                            {editInstructor.is_instructor && (
                                <Form>
                                    <Row>
                                        <Col md={6} className="mt-4">
                                            <Form.Group controlId="formInput1" className="floating-label-group">
                                                <Form.Control
                                                    type="text"
                                                    name="input1"
                                                    value={editInstructor.instructor_qualification.zh}
                                                    onChange={(event) => handleChange(event, "editInstructor.instructor_qualification.zh")}
                                                    className="floating-input"
                                                />
                                                <Form.Label className="floating-label">
                                                    {instructorQualification()}
                                                </Form.Label>
                                            </Form.Group>

                                            <Form.Group controlId="formInput2" className="floating-label-group">
                                                <Form.Control
                                                    type="text"
                                                    name="input2"
                                                    value={editInstructor.instructor_bio.zh}
                                                    onChange={(event) => handleChange(event, "editInstructor.instructor_bio.zh")}
                                                    className="floating-input"
                                                    style={{ height: "90px" }}
                                                />
                                                <Form.Label className="floating-label">{bio()}</Form.Label>
                                            </Form.Group>
                                        </Col>

                                        <Col md={6} className="mt-4">
                                            <Form.Group controlId="formInput3" className="floating-label-group">
                                                <Form.Control
                                                    type="text"
                                                    name="input3"
                                                    value={editInstructor.instructor_qualification.en}
                                                    onChange={(event) => handleChange(event, "editInstructor.instructor_qualification.en")}
                                                    className="floating-input"
                                                />
                                                <Form.Label className="floating-label">
                                                    {instructorQualificationEn()}
                                                </Form.Label>
                                            </Form.Group>

                                            <Form.Group controlId="formInput4" className="floating-label-group">
                                                <Form.Control
                                                    type="text"
                                                    name="input4"
                                                    value={editInstructor.instructor_bio.en}
                                                    onChange={(event) => handleChange(event, "editInstructor.instructor_bio.en")}
                                                    className="floating-input"
                                                    style={{ height: "90px" }}
                                                />
                                                <Form.Label className="floating-label">{bioEn()}</Form.Label>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col sm={12} className="mt-4">
                                            {editInstructor.instructor_avatar ? (
                                                <Image
                                                    src={editInstructor.instructor_avatar}
                                                    alt="Selected Instructor"
                                                    style={{ width: "90px", height: "100px", marginLeft: '0' }}
                                                />
                                            ) : (<React.Fragment></React.Fragment>)}
                                        </Col>
                                        <Col sm={12} className="mt-2">
                                            <Button variant="primary" className="me-2 instructorphotoss" onClick={handleOpen}>
                                                {editInstructor.instructor_avatar ? (
                                                    changeInstructorPhoto()
                                                ) : (addInstructorPhoto())}
                                            </Button>
                                        </Col>
                                        <Col sm={12} className="mt-2">
                                            {(editInstructor.instructor_avatar && previewImage) && <p className="text-danger">{photoNotUpdateHint()}</p>}
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col sm={12} className="mt-4">
                                            <Button variant="primary" className="me-2 instructorphotoss" onClick={handleSave}>
                                                {savechaneg()}
                                            </Button>
                                            {/* <Button variant="outline-primary" className="canslebtn">{cancel()}</Button> */}
                                        </Col>
                                        <Col sm={12} className="mt-2">
                                            {resultMsg && <p className={isSuccess ? "text-success" : "text-danger"}>{resultMsg}</p>}
                                        </Col>
                                    </Row>
                                </Form>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Modal show={showModal} onHide={() => setShowModal(false)} centered size="lg" className="custom-modal" backdrop="static">
                <Modal.Body style={{ height: "360px" }}>
                    <h4 className="maintitle">
                        {editInstructor.instructor_avatar ? (
                            changeInstructorPhoto()
                        ) : (addInstructorPhoto())}</h4>
                    <div style={{ textAlign: "center", height: "156px", padding: '40px', margin: '20px', border: ' 1px solid #D9D9D9' }}>
                        <p>Ratio limit 79:82, format PNG/JPG, e.g. 395(W) * 410(H)</p>
                        <Form.Group controlId="formFile">
                            <input
                                type="file"
                                id="file-input"
                                accept="image/*"
                                onChange={handleFileChange}
                                style={{ display: "none" }}
                            />
                            <Button onClick={handleSelectFile}>Select</Button>
                        </Form.Group>
                        {previewImage && (
                            <Image
                                src={previewImage}
                                alt="Selected Instructor"
                                style={{ width: "90px", height: "100px", marginTop: "55px", marginLeft: '0' }}
                            />
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleModalSave}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
}

export default InstructorSetting;

