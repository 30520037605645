export const topBarHeight = 64;
export const navbarHeight = 60;
export const sideNavWidth = 260;
export const sidenavCompactWidth = 80;
export const containedLayoutWidth = 1200;

export const CAMPAIGN_MEDIAS_MAX = 3;

export const MODE_UNKNOWN = "mode_unknown";
export const MODE_EDIT = "mode_edit";
export const MODE_ADD = "mode_add";

export const TAB_MEMBER_LIST_SEARCH = "1";
export const TAB_MEMBER_LIST_IDENTITY = "2";
export const TAB_MEMBER_LIST_ADVANCE = "3";