import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { MODE_ADD, MODE_EDIT, MODE_UNKNOWN } from "app/utils/constant";
import axios from "axios";

const defaultBasicInfoFormMap = {
    "account_name": { type: "input", val: "", label: "membername" },
    "account_birth": { type: "datepicker", val: "", label: "dobb" },
    "account_gender": {
        type: "select", val: "m", label: "gender",
        options: [{ label: "mMale", value: "m" }, { label: "mFemale", value: "f" }]
    },
    "f_age": { type: "input", val: "", label: "ageb", ignore: true },
    "account_phone": { type: "input", val: "", label: "mobile" },
    "member_id": { type: "input", val: "", label: "memberid" },
    "account_email": { type: "input", val: "", label: "email" },
    "lang": {
        type: "select", val: "zh", label: "preferedlang",
        options: [{ label: "mChinese", value: "zh" }, { label: "mEnglish", value: "en" }]
    },
    "account_address": { type: "input", val: "", label: "mAddress" },
    "account_avatar": { type: "other", val: "", label: "" }
};

const initialVolumnteer = {
    "account_id": "",
    "is_volunteer": false,
    "volunteer_setting": {
        "area": {
            "health": false,
            "education": false,
            "environmental": false,
            "community": false,
            "Animal": false
        },
        "time_of_date": {
            "afternoon": false,
            "evening": false,
            "morning": false
        },
        "days_of_the week": {
            "sunday": false,
            "saturday": false,
            "tuesday": false,
            "wednesday": false,
            "thursday": false,
            "friday": false,
            "monday": false
        },
        "promotion": {
            "post": false,
            "sms": false,
            "email": false
        }
    }
};

const initialInstructor = {
    "instructor_qualification": {
        "en": "",
        "zh": ""
    },
    "instructor_avatar": "",
    "instructor_bio": {
        "en": "",
        "zh": ""
    },
    "account_id": "",
    "is_instructor": false
};

const initialMembership = {
    "account_level": "free",
    "account_identity": "adult",
    "account_status": "active",
    "account_centre": {
        "zh": "",
        "en": ""
    },
    "tags": []
};

const initialMemberSurvey = {
    survey_list: [],
    survey_text: ""
};

const initalMemberSurveyListItem = {
    "survey_id": "",
    "survey_content": {
        "zh": "",
        "en": ""
    },
    "survey_score": 0
};

const initialState = {
    memberList: [],
    filterMemberList: [],
    memberEditMode: MODE_UNKNOWN,
    memberEditData: {},
    basicInfoFormMap: defaultBasicInfoFormMap,
    interestList: [],
    mobileList: [],
    editInterests: [],
    editVolumnteer: initialVolumnteer,
    editInstructor: initialInstructor,
    editMembership: initialMembership,
    editSurvey: initialMemberSurvey
};

export const searchMembers = (data, memberList, callback) => async (dispatch) => {

    let cat = data.cat;
    let keyword = data.keyword

    //client side search member
    // let filterList = [];
    // if (!keyword) {
    //     filterList = memberList;
    // } else {
    //     filterList = memberList.filter((member) => {
    //         switch (cat) {
    //             case "Name":
    //                 return member.account_name.toLowerCase().includes(keyword.toLowerCase());
    //             case "Email":
    //                 return member.account_email.toLowerCase().includes(keyword.toLowerCase());
    //             case "Member ID":
    //                 return member.member_id.toLowerCase().includes(keyword.toLowerCase());
    //             case "Mobile":
    //                 return member.account_phone.toLowerCase().includes(keyword.toLowerCase());
    //             case "Card Ref":
    //                 return member.card_ref.toLowerCase().includes(keyword.toLowerCase());
    //             case "Account ID":
    //                 return member.account_id.toLowerCase().includes(keyword.toLowerCase());
    //             default:
    //                 return false;
    //         }
    //     });
    // }

    // let action = createAction("mMemberReq/tknAware/searchMembers/fulfilled");
    // let simulateResp = {
    //     "code": 1,
    //     "data": filterList
    // };

    // const thunkResult = await dispatch(action(simulateResp));


    //server side search member
    let sendData = {};
    let key = "";
    switch (cat) {
        case "Name":
            key = "account_name";
            break;
        case "Email":
            key = "account_email";
            break;
        case "Member ID":
            key = "member_id";
            break;
        case "Mobile":
            key = "account_phone";
            break;
        case "Card Ref":
            key = "card_ref";
            break;
        case "Account ID":
            key = "account_id";
            break;
    }

    let thunkResult;
    if (key) {
        sendData[key] = keyword;
        thunkResult = await dispatch(serchMembersReq(sendData));
    } else {
        thunkResult = await dispatch(getMembersReq());
    }

    commonHandleError(thunkResult.payload, callback);
};

export const uploadAvatarImage = (accountId, file, callback) => async (dispatch) => {
    const postRes = await axios.post("https://membershipuat.maxisense.io/webapp/memportal/getuploadfileurl",
        {
            "usage": "avatar",
            "file": file.name,
            "account_id": accountId
        });

    let data = postRes.data;

    if (1 == data?.code) {
        let putUrl = data.data.image_upload_url;
        let realImgUrl = data.data.image_url;
        let subName = file.name.substring(file.name.indexOf(".") + ".".length);

        let xhr = new XMLHttpRequest();
        xhr.open("PUT", putUrl, true);
        xhr.setRequestHeader("Content-Type", "image/" + subName);
        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    callback(realImgUrl, "");
                } else {
                    callback(null, "upload failed:,", xhr.status, xhr.responseText);
                }
            }
        };

        xhr.send(file);
    } else {
        if (!data) {
            callback(null, "upload failed: no data");
        }
        else {
            callback(null, "upload failed, code:" + data.code + " message:" + data.message_en);
        }
    }
};

export const getMemberDetail = (accountId, callback) => async (dispatch) => {
    let thunkResult;
    if (!accountId) {
        let action = createAction("mMemberReq/tknAware/getMemberDetail/fulfilled");
        let simulateResp = {
            "code": 1,
            "data": {}
        };

        thunkResult = await dispatch(action(simulateResp));
    } else {
        let sendData = {
            "account_id": accountId
        };

        thunkResult = await dispatch(getMemberDetailReq(sendData));
    }

    commonHandleError(thunkResult.payload, callback);
};


export const getMembers = () => async (dispatch) => {
    await dispatch(getMembersReq());
}

export const getInterestList = () => async (dispatch) => {
    await dispatch(getInterestListReq());
};

export const getMobileList = (sendData) => async (dispatch) => {
    await dispatch(getMobileListReq(sendData));
};

export const createMember = (sendData, callback) => async (dispatch) => {
    const thunkResult = await dispatch(createMemberReq(sendData));
    commonHandleError(thunkResult.payload, callback);
};

export const updateMember = (sendData, callback) => async (dispatch) => {
    const thunkResult = await dispatch(updateMemberReq(sendData));
    commonHandleError(thunkResult.payload, callback);
};

export const deleteMember = (sendData, callback) => async (dispatch) => {
    const thunkResult = await dispatch(deleteMemberReq(sendData));
    commonHandleError(thunkResult.payload, callback);
};

export const updateInterests = (sendData, callback) => async (dispatch) => {
    const thunkResult = await dispatch(updateInterestsReq(sendData));
    commonHandleError(thunkResult.payload, callback);
};

export const updatePwd = (sendData, callback) => async (dispatch) => {
    const thunkResult = await dispatch(updatePwdReq(sendData));
    commonHandleError(thunkResult.payload, callback);
};

export const updateInstructor = (sendData, callback) => async (dispatch) => {
    const thunkResult = await dispatch(updateInstructorReq(sendData));
    commonHandleError(thunkResult.payload, callback);
};

export const updateVolumnteer = (sendData, callback) => async (dispatch) => {
    const thunkResult = await dispatch(updateVolumnteerReq(sendData));
    commonHandleError(thunkResult.payload, callback);
};

export const getSurvey = (sendData, callback) => async (dispatch) => {
    const thunkResult = await dispatch(getSurveyReq(sendData));
    commonHandleError(thunkResult.payload, callback);
};

export const updateSurvey = (sendData, callback) => async (dispatch) => {
    const thunkResult = await dispatch(updateSurveyReq(sendData));
    commonHandleError(thunkResult.payload, callback);
};

const getMembersReq = createAsyncThunk("mMemberReq/tknAware/getMembers", async () => {
    let res = await axios.get("https://membershipuat.maxisense.io/webapp/memportal/getmemberlist");
    return res.data;
});

//TODO peteryeh search member is not ready
const serchMembersReq = createAsyncThunk("mMemberReq/tknAware/searchMembers", async (data) => {
    let res = await axios.post("https://membershipuat.maxisense.io/webapp/memportal/searchmember", data);
    return res.data;
});

const createMemberReq = createAsyncThunk("mMemberReq/tknAware/createMember", async (data) => {
    let res = await axios.post("https://membershipuat.maxisense.io/webapp/memportal/addmemberbasic", data);
    // const res = await axios.post("http://localhost:3007/createMember", data);
    return res.data;
});

const updateMemberReq = createAsyncThunk("mMemberReq/tknAware/updateMember", async (data) => {
    let res = await axios.post("https://membershipuat.maxisense.io/webapp/memportal/updatememberbasic", data);
    // const res = await axios.post("http://localhost:3007/updateMember", data);
    return res.data;
});

const getMemberDetailReq = createAsyncThunk("mMemberReq/tknAware/getMemberDetail", async (data) => {
    let res = await axios.post("https://membershipuat.maxisense.io/webapp/memportal/getmemberdetail", data);
    return res.data;
});

const getInterestListReq = createAsyncThunk("mMemberReq/tknAware/getInterestList", async () => {
    let res = await axios.get("https://membershipuat.maxisense.io/webapp/memportal/getinterestlist");
    return res.data;
});

const getMobileListReq = createAsyncThunk("mMemberReq/tknAware/getMobileList", async (data) => {
    let res = await axios.post("https://membershipuat.maxisense.io/webapp/memportal/getmembermobilelist", data);
    return res.data;
});

const updateInterestsReq = createAsyncThunk("mMemberReq/tknAware/updateInterests", async (data) => {
    let res = await axios.post("https://membershipuat.maxisense.io/webapp/memportal/updatememberinterest", data);
    return res.data;
});

const deleteMemberReq = createAsyncThunk("mMemberReq/tknAware/deleteMember", async (data) => {
    let res = await axios.post("https://membershipuat.maxisense.io/webapp/memportal/deleteaccount", data);
    return res.data;
});

const updateInstructorReq = createAsyncThunk("mMemberReq/tknAware/updateInstructor", async (data) => {
    let res = await axios.post("https://membershipuat.maxisense.io/webapp/memportal/updatememberinstructor", data);
    return res.data;
});

const updatePwdReq = createAsyncThunk("mMemberReq/tknAware/updatePwd", async (data) => {
    let res = await axios.post("https://membershipuat.maxisense.io/webapp/memportal/updatememberpassword", data);
    return res.data;
});

const updateVolumnteerReq = createAsyncThunk("mMemberReq/tknAware/updateVolumnteer", async (data) => {
    let res = await axios.post("https://membershipuat.maxisense.io/webapp/memportal/updatemembervolunteer", data);
    return res.data;
});

const getSurveyReq = createAsyncThunk("mMemberReq/tknAware/getSurvey", async (data) => {
    //TODO peteryeh 待確認

    let res = await axios.post("http://localhost:3007/getmemberSurvey", data);
    // let res = await axios.post("https://membershipuat.maxisense.io/webapp/memportal/getmemberSurvey", data);
    return res.data;
});

const updateSurveyReq = createAsyncThunk("mMemberReq/tknAware/updateSurvey", async (data) => {
    //TODO peteryeh 待確認
    let res = await axios.post("http://localhost:3007/updatememberSurvey", data);
    // let res = await axios.post("https://membershipuat.maxisense.io/webapp/memportal/updatememberSurvey", data);
    return res.data;
});

const commonHandleError = (data, callback) => {
    if (callback) {
        let baseErrorMsgObj = {
            message: "系統發生問題, 請稍後再試",
            message_en: "An unexpected error occurred. Please try again."
        }

        if (1 == data?.code) {
            callback(true, data);
        } else {
            callback(false, (data) ? (data) : (baseErrorMsgObj))
        }
    }
};

const calculateAge = (dob) => {
    if (!dob) return "";

    const birthDate = new Date(dob);
    const today = new Date();

    let years = today.getFullYear() - birthDate.getFullYear();

    const isBeforeBirthdayThisYear =
        today.getMonth() < birthDate.getMonth() ||
        (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate());

    if (isBeforeBirthdayThisYear) {
        years -= 1;
    }

    return (`${years} ${years !== 1 ? '' : ''}`);
};

const setValByPath = (obj, path, value) => {
    let keys = path.split(/\.|\|/g); // Allow split . or |
    let lastKey = keys.pop(); // Get the last key
    let target = obj; // Reference to the original object

    // Traverse the object to the second last key
    for (let key of keys) {
        if (!(key in target) || typeof target[key] !== "object") {
            target[key] = {}; // Create an object if path is missing
        }
        target = target[key]; // Move deeper
    }

    target[lastKey] = value; // Set the final value
}

const assignFromBase = (base, dta) => {
    const result = Object.keys(base).reduce((acc, key) => {
        if (dta.hasOwnProperty(key)) {
            acc[key] = dta[key]; // 只更新 base 中已存在的 key
        }
        return acc;
    }, { ...base });

    return result;
}

const sortDate = (a, b) => {
    const getDate = (entry) => {
        if (!entry.create_date) return 0;
        if (Array.isArray(entry.create_date)) {
            return entry.create_date[0] || 0;
        }
        return entry.create_date;
    };

    const dateA = getDate(a);
    const dateB = getDate(b);
    return dateB - dateA;
};

const rdxMemberSlice = createSlice({
    initialState,
    name: "rdxMember",
    extraReducers: (builder) => {
        builder
            .addCase(getMembersReq.fulfilled, (state, actions) => {
                let data = actions.payload;
                if (data.code === 1 && data.data) {
                    state.memberList = data.data.sort(sortDate);
                    state.filterMemberList = data.data.sort(sortDate);
                }
            })
            .addCase(serchMembersReq.fulfilled, (state, actions) => {
                let data = actions.payload;
                if (data.code === 1 && data.data) {
                    state.filterMemberList = data.data.sort(sortDate);
                }
            })
            .addCase(getMemberDetailReq.fulfilled, (state, actions) => {
                let data = actions.payload;
                if (data.code === 1) {
                    let origMemberData = data.data;

                    //TODO peteryeh 等 days_of_the' 'week 錯別字修正後移除
                    if (origMemberData.volunteer_setting && origMemberData.volunteer_setting["days_of_the week"]) {
                        origMemberData.volunteer_setting["days_of_the_week"] = origMemberData.volunteer_setting["days_of_the week"];
                        delete origMemberData.volunteer_setting["days_of_the week"];
                    }

                    try {
                        if (origMemberData.account_birth) {
                            origMemberData.account_birth = origMemberData.account_birth.substring(0, origMemberData.account_birth.lastIndexOf("-")) + "-01";
                        }
                    } catch (err) { }

                    state.memberEditData = origMemberData;
                    if (!data.data.account_id) {
                        state.memberEditMode = MODE_ADD;
                    } else {
                        state.memberEditMode = MODE_EDIT;
                    }
                }
            })
            .addCase(getInterestListReq.fulfilled, (state, actions) => {
                let data = actions.payload;
                if (data.code === 1) {
                    state.interestList = data.data;
                }
            })
            
            .addCase(getMobileListReq.fulfilled, (state, actions) => {
                let data = actions.payload;
                if (data.code === 1) {
                    state.mobileList = data.data;
                }
            })
            .addCase(getSurveyReq.fulfilled, (state, actions) => {
                let data = actions.payload;
                if (data.code === 1 && data.data) {
                    state.editSurvey = data.data;
                }
            });
    },
    reducers: {
        setEditMemberData(state, actions) {
            let nextEditMember = { ...actions.payload };
            state.memberEditData = nextEditMember;

            // console.log(nextEditMember);
            for (const key in state.basicInfoFormMap) {
                state.basicInfoFormMap[key].val = nextEditMember[key] || state.basicInfoFormMap[key].val;
            }

            let birth = nextEditMember["account_birth"];
            if (birth) {
                state.basicInfoFormMap["f_age"].val = calculateAge(birth);
            }
        },
        clearEditMember(state) {
            state.memberEditMode = MODE_UNKNOWN;
            state.memberEditData = {};
            state.basicInfoFormMap = defaultBasicInfoFormMap;
        },
        resetAllMemberPageData(state) {
            state = initialState;
        },
        setMemberPageFormData(state, actions) {
            let { data, projection } = actions.payload;
            state[projection][data.key].val = data.val;
        },
        setPageFormToMemberData(state, actions) {
            let existFormMap = actions.payload.formMap;

            for (const key in existFormMap) {
                let obj = existFormMap[key];
                if (!obj.ignore) {
                    state.memberEditData[key] = obj.val;
                }
            }
        },
        initSimpleData(state, actions) {
            let { data, projection, type } = actions.payload;

            if ("list" == type) {
                let resultData = (data) ? data : [];
                state[projection] = resultData;
            } else if ("obj" == type) {
                let base = {};
                switch (projection) {
                    case "editVolumnteer": base = initialVolumnteer; break;
                    case "editInstructor": base = initialInstructor; break;
                    case "editMembership": base = initialMembership; break;
                    case "editSurvey": base = initialMemberSurvey; break;
                }

                let obj = assignFromBase(base, data);
                state[projection] = obj;
            }
        },
        setSimpleData(state, actions) {
            let { data, projection, path } = actions.payload;

            if (Array.isArray(data) && !path) {
                state[projection] = data;
            } else {
                let formatPath;
                if (path.indexOf(projection) == 0) {
                    let headStr = projection + ".";
                    formatPath = path.substring(headStr.length);
                } else {
                    formatPath = path;
                }
                setValByPath(state[projection], formatPath, data);
            }
        },
        setSimpleDataToMemberData(state, actions) {
            let { data, projection, base } = actions.payload;

            if (Array.isArray(data)) {
                // console.log("list update:", projection, data);
                state.memberEditData[projection] = data;
            } else {
                let obj = Object.assign({}, base, data);
                if ("self" == projection) {
                    // console.log("self update:", projection, obj);
                    state.memberEditData = obj;
                } else {
                    // console.log("partial update:", projection, obj);
                    state.memberEditData[projection] = obj;
                }
            }
        }
    }
});

export const {
    setEditMemberData, clearEditMember, resetAllMemberPageData,
    setMemberPageFormData, setPageFormToMemberData,
    initSimpleData, setSimpleData, setSimpleDataToMemberData } = rdxMemberSlice.actions;

export const rdxMemberReducer = rdxMemberSlice.reducer;