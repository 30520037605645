import React, { useState, useEffect } from 'react';
import { Container, Row, Button, Card, Modal, Form, Col } from 'react-bootstrap';
import './campaigncss/FileUpload.css'
import useTranslations from '../transalation/TranslationUtils';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { initCampaignSimpleData, setCampaignSimpleData, setCampaignSimpleDataToCampaignData, updateMediaFiles, uploadCampaignImage, uploadCampaignImageBunch } from 'app/redux/slices/rdxCampaignSlice';
import CancelIcon from '@mui/icons-material/Cancel';
import { CAMPAIGN_MEDIAS_MAX } from 'app/utils/constant';

function FileUpload({ }) {
    const dispatch = useDispatch();
    const campaignDetails = useSelector((state) => state.rdxCampaign?.campaignEditData || {});
    const editFiles = useSelector((state) => state.rdxCampaign?.editFiles || {});
    const [showMediasModal, setShowMediasModal] = useState(false);
    const [showActIconModal, setShowActIconModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null); // To preview the selected image or PDF
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [previewUrls, setPreviewUrls] = useState([]);

    useEffect(() => {
        let data = {
            data: { ...campaignDetails },
            projection: "editFiles",
            type: "obj"
        };
        dispatch(initCampaignSimpleData(data));
    }, []);


    // Handle opening and closing modals
    const handleOpenMediasModal = () => {
        setSelectedFiles([]); // Reset the file when opening modal
        setPreviewUrls([]);
        setShowMediasModal(true);
    };

    // const handleCloseMediasModal = () => setShowMediasModal(false);
    const handleCloseMediasModal = async () => {
        setShowMediasModal(false);
        setSelectedFiles([]);
        setPreviewUrls([]);
    };

    const handleOpenActIconModal = () => {
        setSelectedFile(null);
        setPreviewUrl(null);
        setShowActIconModal(true);
    };
    const handleCloseActIconModal = () => setShowActIconModal(false);

    // Handle save medias
    const handleSaveMedias = async () => {
        if (CAMPAIGN_MEDIAS_MAX != -1) {
            // Check if the number of selected files exceeds the limit of 3
            if (selectedFiles.length + editFiles.campaign_medias.length > CAMPAIGN_MEDIAS_MAX) {
                alert("You can only select up to " + CAMPAIGN_MEDIAS_MAX + " images/videos.");
                return;
            }
        }

        let fileInfoList = [];
        let count = 0;
        let files = selectedFiles;
        let errMsg = "";
        let callback = (fileObj, msg) => {
            if (errMsg) { return; }

            if (!fileObj) {
                errMsg = msg;
                alert(errMsg);
                return;
            } else {
                count++;
                if (count == files.length) {
                    proceedSaveMedias(fileInfoList);
                }
            }
        }

        files.forEach((item) => {
            fileInfoList.push({
                file: item,
                type: "",
                fileSrcUrl: ""
            });
        });

        fileInfoList.forEach((item) => {
            dispatch(uploadCampaignImageBunch(item, callback));
        });
    };

    const proceedSaveMedias = async (fileInfoList) => {
        let newRows = [...editFiles.campaign_medias];
        let newUploadedList = [];

        fileInfoList.forEach((item) => {
            newUploadedList.push({
                "type": item.type,
                "url": item.fileSrcUrl
            })
        });

        newRows = newRows.concat(newUploadedList);

        let data = {
            data: newRows,
            projection: "editFiles",
            path: "campaign_medias"
        };

        dispatch(setCampaignSimpleData(data));

        handleCloseMediasModal();
    }

    // Handle save activity icon
    const handleSaveActivityIcon = async () => {
        dispatch(uploadCampaignImage(selectedFile, (imgUrl, msg) => {
            if (imgUrl) {
                let data = {
                    data: imgUrl,
                    projection: "editFiles",
                    path: "campaign_icon"
                }
                dispatch(setCampaignSimpleData(data));
                handleCloseActIconModal();
            } else {
                alert(msg);
            }
        }));
    }


    const handleFileChange = (e) => {
        const file = e.target.files[0];

        setSelectedFile(file);
        setPreviewUrl(URL.createObjectURL(file));
    };

    const handleFilesChange = (e) => {
        const files = Array.from(e.target.files);

        if (CAMPAIGN_MEDIAS_MAX != -1) {
            // Check if the number of selected files exceeds the limit of 3
            if (selectedFiles.length + files.length + editFiles.campaign_medias.length > CAMPAIGN_MEDIAS_MAX) {
                alert("You can only select up to " + CAMPAIGN_MEDIAS_MAX + " images/videos.");
                return;
            }
        }

        // Add new files to the list
        const updatedFiles = [...selectedFiles, ...files];
        setSelectedFiles(updatedFiles);

        // Preview the images/videos
        const newPreviewUrls = files.map((file) => URL.createObjectURL(file));
        setPreviewUrls((prev) => [...prev, ...newPreviewUrls]);
    };

    const handleSaveAllMedias = () => {
        if (!editFiles.campaign_medias || !editFiles.campaign_icon) {
            alert("Please upload campaign images/videos and event images.");
            return;
        }

        if (CAMPAIGN_MEDIAS_MAX != -1) {
            // Check if the number of selected files exceeds the limit of 3
            if (selectedFiles.length + editFiles.campaign_medias.length > CAMPAIGN_MEDIAS_MAX) {
                alert("You can only select up to " + CAMPAIGN_MEDIAS_MAX + " images/videos.");
                return;
            }
        }

        dispatch(updateMediaFiles(editFiles, (isSuccess, data) => {
            if (isSuccess) {
                alert("Upload Campaign Photo/Video successfully!");

                let rData = {
                    data: editFiles,
                    projection: "self",
                    base: campaignDetails
                };
                dispatch(setCampaignSimpleDataToCampaignData(rData));
            } else {
                alert(showErrMsg(data));
            }
        }));
    }

    // Remove a selected file
    const handleRemoveExistMedias = (index) => {
        let newRows = [...editFiles.campaign_medias];
        newRows.splice(index, 1);

        let data = {
            data: newRows,
            projection: "editFiles",
            path: "campaign_medias"
        };

        dispatch(setCampaignSimpleData(data));
    };

    // Remove a selected file
    const handleRemoveFile = (index) => {
        const updatedFiles = selectedFiles.filter((_, i) => i !== index);
        setSelectedFiles(updatedFiles);

        const updatedPreviewUrls = previewUrls.filter((_, i) => i !== index);
        setPreviewUrls(updatedPreviewUrls);
    };

    // const [showBasicModal, setShowBasicModal] = useState(false);
    // const handleshowBasicModal = () => setShowBasicModal(true);
    // const handlecloseBasicModal = () => setShowBasicModal(false);


    const { cancel, save, uploadCampaignPhotoVideo, addActivityPhotoVideo, addActivityIcon, showErrMsg } = useTranslations();

    return (
        <Container >
            <Row>
                <Card className="mt-0 border-0" style={{ position: 'relative' }}>
                    <Card.Body>
                        <Row className="align-items-center mb-3">
                            <Col md={6} className="d-flex align-items-center">
                                <h5 className="basicheading">{uploadCampaignPhotoVideo()}</h5>
                            </Col>
                            <Col md={6} className="text-end">
                                {/* <Button variant="secondary" className="basiccancelbtnn me-2">{cancel()}</Button> */}
                                <Button variant="primary" className="basicsavebtn me-3"
                                    onClick={() => handleSaveAllMedias()}>{save()}</Button>
                            </Col>
                        </Row>
                        <br />
                        {editFiles.campaign_medias?.length > 0 && (
                            <Row xs="auto">
                                {editFiles.campaign_medias?.map((item, index) => (
                                    <Col key={index} md={6} lg={3}>
                                        {("image" == item.type) ? (
                                            <img src={item.url} alt={`Preview ${index}`} style={{ width: '100%' }} />
                                        ) : (
                                            <video style={{ width: '100%' }} controls>
                                                <source src={item.url} />
                                            </video>
                                        )}
                                        <CancelIcon
                                            color="error" size="large"
                                            sx={{
                                                fontSize: 36, cursor: "pointer", position: "absolute", "margin-left": "-16px", "margin-top": "-20px"
                                            }}
                                            onClick={() => handleRemoveExistMedias(index)} />
                                    </Col>
                                ))}
                            </Row>
                        )}
                        <br />
                        <Row>
                            <Button style={{ width: '200px', fontSize: '13px' }} onClick={handleOpenMediasModal} className='filebtn ms-2'>
                                {addActivityPhotoVideo()}
                            </Button>
                        </Row>
                        <br />
                        <Row>
                            <div style={{ width: '100%', height: '2px', background: '#e0e0e0' }}></div>
                        </Row>
                        <br />
                        {editFiles.campaign_icon && (
                            <Row xs="auto">
                                <Col md={6} lg={4}>
                                    <img src={editFiles.campaign_icon} alt={`Preview act icon`} style={{ width: '100%' }} />
                                </Col>
                            </Row>
                        )}
                        <br />
                        <Row>
                            <Button style={{ width: '200px', fontSize: '13px' }} onClick={handleOpenActIconModal} className='filebtn ms-2'>
                                {addActivityIcon()}
                            </Button>
                        </Row>
                    </Card.Body>
                </Card>
            </Row>

            <Modal show={showMediasModal} onHide={handleCloseMediasModal} centered size="lg" className="custom-modal">
                <Modal.Body style={{ height: '560px' }}>
                    <h4 className="maintitle">{addActivityPhotoVideo()}</h4>
                    <div style={{ textAlign: 'center', height: '156px', padding: '40px', margin: '20px', border: '1px solid #D9D9D9' }}>
                        <p>Ratio limit 4:3, e.g. 323(W) * 180(H). File size: within 500MB</p>
                        <Form.Group controlId="formFile">
                            <input
                                type="file"
                                id="file-input"
                                accept="image/*,video/*"
                                onChange={handleFilesChange}
                                style={{ display: 'none' }}
                                multiple // Allow multiple files
                            />
                            <Button
                                onClick={() => document.getElementById('file-input').click()}
                                disabled={selectedFiles.length >= 3} // Disable if 3 files are selected
                            >
                                Select
                            </Button>
                        </Form.Group>
                    </div>

                    {/* Preview Uploaded Files */}
                    {previewUrls.length > 0 && (
                        <Row xs="auto">
                            {previewUrls.map((item, index) => (
                                <Col key={index} md={2} lg={4}>
                                    {(selectedFiles[index].type.startsWith('image/')) ? (
                                        <img src={item} alt={`Preview ${index}`} style={{ width: '100%', "max-height": "290px" }} />
                                    ) : (
                                        <video style={{ width: '100%', "max-height": "290px" }} controls>
                                            <source src={item} />
                                        </video>
                                    )}
                                    <CancelIcon
                                        color="error" size="large"
                                        sx={{
                                            fontSize: 36, cursor: "pointer", position: "absolute", "margin-left": "-16px", "margin-top": "-20px"
                                        }}
                                        onClick={() => handleRemoveFile(index)} />
                                </Col>
                            ))}
                        </Row>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseMediasModal}>
                        {cancel()}
                    </Button>
                    <Button variant="primary" onClick={handleSaveMedias} disabled={selectedFiles.length === 0}>
                        {save()}
                    </Button>
                </Modal.Footer>
            </Modal>


            {/* Activity Icon Upload Modal */}
            <Modal show={showActIconModal} onHide={handleCloseActIconModal} centered size="lg" className="custom-modal">
                <Modal.Body style={{ height: '560px' }}>
                    <h4 className="maintitle">{addActivityIcon()}</h4>
                    <div style={{ textAlign: 'center', height: '156px', padding: '40px', margin: '20px', border: '1px solid #D9D9D9' }}>
                        <p>Ratio limit 4:3, e.g. 79(W) * 92(H). File size: within 10MB</p>
                        <Form.Group controlId="formFileActIcon">
                            <input
                                type="file"
                                id="file-input-doc"
                                accept="image/*"
                                onChange={handleFileChange}
                                style={{ display: 'none' }}
                            />
                            <Button onClick={() => document.getElementById('file-input-doc').click()}>Select</Button>
                        </Form.Group>
                    </div>

                    {/* Activity Icon Preview*/}
                    {previewUrl && (
                        <div style={{ textAlign: 'left', marginTop: '20px' }}>
                            <img src={previewUrl} alt="Activity Icon Preview" style={{ width: '66px', height: '73px' }} />
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseActIconModal}>
                        {cancel()}
                    </Button>
                    <Button variant="primary" onClick={handleSaveActivityIcon} disabled={!selectedFile}>
                        {save()}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
}

export default FileUpload;