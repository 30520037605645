

import React, { useState } from "react";
import { Container, Row, Col, Card, Button, Form, Table, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./membercss/Campaignments.css";
import Avatars from './Avatars';
import useTranslations from "../transalation/TranslationUtils";
import { useSelector } from "react-redux";
import { Box, CardActions, CardContent, Divider, Rating, TextField, Typography } from "@mui/material";
import { getSurvey, setSimpleData, updateSurvey } from "app/redux/slices/rdxMemberSlice";
import { useDispatch } from "react-redux";
function Campaignengagement() {
    const dispatch = useDispatch();
    const memberEditData = useSelector((state) => state.rdxMember?.memberEditData || {});
    const editSurvey = useSelector((state) => state.rdxMember?.editSurvey || {});
    const [activeTab, setActiveTab] = useState("In-Progress");
    const [showRateModal, setShowRateModal] = useState(false);

    const [engageMntist, setEngageMntList] = useState(
        [
            {
                "AA": "Painting Class",
                "BB": "E(社交康樂)",
                "CC": "643250",
                "DD": "2/12/2024",
                "EE": "03/04/2024",
                "campaign_id": "sa"
            },
            {
                "AA": "Painting Class",
                "BB": "E(社交康樂)",
                "CC": "643250",
                "DD": "12/02/2024",
                "EE": "04/03/2024",
                "campaign_id": "sb"
            },
            {
                "AA": "Painting Class",
                "BB": "E(社交康樂)",
                "CC": "643250",
                "DD": "12/02/2024",
                "EE": "04/03/2024",
                "campaign_id": "sc"
            }
        ]
    );


    const { campaignEngagement,
        campaignDetails,
        inProgress,
        completed,
        applicationStatus,
        campaignName,
        type,
        code,
        startDate,
        endDate,
        every,
        totalClasses,
        attendance,
        absence,
        reason, campaignname, func_t, pushstatus,
        close, mUpdate, mSend, mQuestion, i18nObj,
        mSuggestion, showErrMsg } = useTranslations();

    const onCompletedItemClick = (index) => {
        // console.log(index);
        let sendData = {
            "campaign_id": engageMntist[index].campaign_id
        }
        dispatch(getSurvey(sendData, (isSuccess, data) => {
            if (!isSuccess) {
                alert(showErrMsg(data));
            } else {
                setShowRateModal(true);
            }
        }))
    }

    const handleChange = (val, path) => {
        if (val == undefined || val == null) { return; }
        let data = {
            data: val,
            projection: "editSurvey",
            path: path
        }
        dispatch(setSimpleData(data));
    };

    const handeUpdateSurvey = async () => {
        //TODO peteryeh 待確認
        let sendData = editSurvey;
        dispatch(updateSurvey(sendData, (isSuccess, data) => {
            alert(showErrMsg(data));
            if (isSuccess) {
                setShowRateModal(false);
            }
        }))
    }

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const counts = {
        "In-Progress": 1,
        Completed: 3,
        "Application Status": 2,
    };

    return (
        <Container fluid>
            <Row>
                <Col md={12}>
                    <Card className="mt-0 border-0" style={{ position: "relative" }}>
                        <Card.Body>
                            <Avatars initAvatar={memberEditData["account_avatar"]} />
                            <br />
                            <Card className="m-3">
                                <Card.Body>
                                    <Row>
                                        <h5 className="basicinfo">{campaignDetails()}</h5>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col xs={12} md={7} className="d-flex justify-content-between">
                                            {["In-Progress", "Completed", "Application Status"].map((tab, index) => (
                                                <div
                                                    key={index}
                                                    className={`tab-link ${activeTab === tab ? "active" : ""}`}
                                                    onClick={() => handleTabClick(tab)}
                                                    style={{
                                                        cursor: "pointer",
                                                        fontWeight: activeTab === tab ? "bold" : "normal",
                                                        color: activeTab === tab ? "blue" : "black",
                                                        paddingBottom: "5px",
                                                        borderBottom: activeTab === tab ? "2px solid blue" : "none",
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    <span>{tab}</span>
                                                    <br />
                                                    <small style={{ color: "#6c757d", fontSize: "12px" }}>
                                                        {counts[tab]} {counts[tab] === 1 ? "" : ""}
                                                    </small>
                                                </div>
                                            ))}
                                        </Col>
                                    </Row>

                                    {/* Conditional Rendering for Tables */}
                                    {activeTab === "In-Progress" && (
                                        <Row className="in-proogress">
                                            <div className="table-responsive">
                                                <Table striped hover className="mt-3">
                                                    <thead>
                                                        <tr>
                                                            <th className="custom-th">{campaignName()}</th>
                                                            <th className="custom-th">{type()}</th>
                                                            <th className="custom-th">{code()}</th>
                                                            <th className="custom-th">{startDate()}</th>
                                                            <th className="custom-th">{endDate()}</th>
                                                            <th className="custom-th">{every()}</th>
                                                            <th className="custom-th">{totalClasses()}</th>
                                                            <th className="custom-th">{attendance()}</th>
                                                            <th className="custom-th">{absence()}</th>
                                                            <th className="custom-th">{reason()}</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="custom-td">Painting Class</td>
                                                            <td className="custom-td">E(社交康樂)</td>
                                                            <td className="custom-td">643250</td>
                                                            <td className="custom-td"> 02/12/2024</td>
                                                            <td className="custom-td">03/04/2024</td>
                                                            <td className="custom-td">Tue</td>
                                                            <td className="custom-td">2</td>
                                                            <td className="custom-td">50%</td>
                                                            <td className="custom-td">1</td>
                                                            <td className="custom-td">Sick</td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </Row>
                                    )}

                                    {activeTab === "Completed" && (
                                        <Row className="complete">
                                            <div className="table-responsive">
                                                <Table striped hover className="mt-3">
                                                    <thead>
                                                        <tr>
                                                            <th className="custom-th">{campaignName()}</th>
                                                            <th className="custom-th">{type()}</th>
                                                            <th className="custom-th">{code()}</th>
                                                            <th className="custom-th">{startDate()}</th>
                                                            <th className="custom-th">{endDate()}</th>
                                                            <th className="custom-th">{func_t()}</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {engageMntist.map((item, index) => (
                                                            <tr key={index}>
                                                                <td className="custom-td">{item.AA}</td>
                                                                <td className="custom-td">{item.BB}</td>
                                                                <td className="custom-td">{item.CC}</td>
                                                                <td className="custom-td">{item.DD}</td>
                                                                <td className="custom-td">{item.EE}</td>
                                                                <td className="custom-td">
                                                                    <Button variant="primary" className="me-2 feedback"
                                                                        onClick={(e) => onCompletedItemClick(index)}>
                                                                        View Feedback
                                                                    </Button></td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </Row>
                                    )}

                                    {activeTab === "Application Status" && (
                                        <Row className="application-status">
                                            <div className="table-responsive">
                                                <Table striped hover className="mt-3">
                                                    <thead>
                                                        <tr>
                                                            <th className="custom-th">{campaignName()}</th>
                                                            <th className="custom-th">Method</th>
                                                            <th className="custom-th">{type()}</th>
                                                            <th className="custom-th">{code()}</th>
                                                            <th className="custom-th">{startDate()}</th>
                                                            <th className="custom-th">{endDate()}</th>
                                                            <th className="custom-th">{every()}</th>
                                                            <th className="custom-th">{pushstatus()}</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="custom-td">Singing Class</td>
                                                            <td className="custom-td">First Come First Serve</td>
                                                            <td className="custom-td">E(社交康樂)</td>
                                                            <td className="custom-td">786574</td>
                                                            <td className="custom-td">06/19/2024</td>
                                                            <td className="custom-td">09/18/2024</td>
                                                            <td className="custom-td">Tue, Thur</td>
                                                            <td className="custom-td">Success</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="custom-td">Singing Class</td>
                                                            <td className="custom-td">First Come First Serve</td>
                                                            <td className="custom-td">E(社交康樂)</td>
                                                            <td className="custom-td">786574</td>
                                                            <td className="custom-td">06/19/2024</td>
                                                            <td className="custom-td">09/18/2024</td>
                                                            <td className="custom-td">Tue, Thur</td>
                                                            <td className="custom-td">Pending Approval
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </Row>
                                    )}
                                </Card.Body>
                            </Card>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Modal
                show={showRateModal}
                centered
                className="custom-modal-width">
                <Modal.Body>
                    <Box>
                        {editSurvey.survey_list?.map((item, index) => (
                            <React.Fragment key={index}>
                                <Card>
                                    <CardContent>
                                        <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>{mQuestion()}</Typography>
                                        <Typography variant="h5" component="div">{i18nObj(item.survey_content)}</Typography>
                                    </CardContent>
                                    <div style={{ "height": "1px", "margin": "0px 13px", "background": "#cccccc" }}></div>
                                    <CardActions>
                                        <Rating
                                            className="ms-1"
                                            name={"simple-controlled" + index}
                                            value={item.survey_score}
                                            onChange={(event, newValue) => {
                                                handleChange(newValue, "editSurvey|survey_list|" + index + "|survey_score");
                                            }}
                                        />
                                    </CardActions>
                                </Card>
                                <br />
                            </React.Fragment>
                        ))}

                        <TextField
                            label={mSuggestion()}
                            value={editSurvey.survey_text}
                            onChange={(event) => {
                                handleChange(event?.target?.value, "editSurvey.survey_text");
                            }}
                            multiline
                        />
                    </Box>
                </Modal.Body>

                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => { setShowRateModal(false); }}
                        className="me-2"
                    >
                        {close()}
                    </Button>
                    <Button
                        variant="warning"
                        onClick={() => {
                            handeUpdateSurvey();
                        }}>
                        {mUpdate()}/{mSend()}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
}

export default Campaignengagement;
