import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    navItems: [],
    pagePermissions: {},
    audienceIdMap: {},
    memberIdentOptions: [],
    cmpgnAuditOptions: []
};

export const getAudienceList = () => async (dispatch) => {
    await dispatch(getAudienceListReq());
};

const getAudienceListReq = createAsyncThunk("mMainReq/tknAware/getAudienceList", async () => {
    let res = await axios.get("https://membershipuat.maxisense.io/webapp/memportal/gettargetaudiencelist");
    return res.data;
});

const rdxMainSlice = createSlice({
    initialState,
    name: "rdxMain",
    extraReducers: (builder) => {
        builder
            .addCase(getAudienceListReq.fulfilled, (state, actions) => {
                let data = actions.payload;
                if (data.code === 1) {
                    let origAudData = data.data;

                    let idMapResult = {};
                    let cmpgnOptResult = [];
                    let mbrOptResult = [];

                    let matches = ["adult", "presenior", "senior"];
                    origAudData.forEach((item) => {
                        idMapResult[item.audience_id] = item;
                        cmpgnOptResult.push(item);

                        if (matches.includes(item.audience_id)) {
                            mbrOptResult.push(item);
                        }


                    });

                    state.audienceIdMap = idMapResult;
                    state.cmpgnAuditOptions = cmpgnOptResult;
                    state.memberIdentOptions = mbrOptResult;
                }
            });
    },
    reducers: {
        updateNavViewData(state, actions) {
            state.navItems = actions.payload;
        },
        setPagePermissions(state, actions) {
            let permissions = actions.payload;

            let result = {};
            permissions.forEach((item) => {
                let arr = item.split("_");
                let cat = arr[0];
                let func = arr[1];

                if (!result[cat]) {
                    result[cat] = {};
                }

                if ("campaign_review" == item) {
                    result["campaign"]["campaign_review"] = true;
                } else if (func) {
                    switch (func) {
                        case "u":
                            result[cat]["add"] = true;
                            result[cat]["edit"] = true;
                            break;
                        case "d":
                            result[cat]["delete"] = true;
                            break;
                    }
                }
            });

            state.pagePermissions = result;
        },
    }
});

export const { updateNavViewData, setPagePermissions } = rdxMainSlice.actions;

export const rdxMainReducer = rdxMainSlice.reducer;