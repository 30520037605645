import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { MODE_ADD, MODE_EDIT, MODE_UNKNOWN } from "app/utils/constant";
import axios from "axios";

export const initialCampaignBasicInfo = {
    "campaign_name": {
        "zh": "",
        "en": ""
    },
    "campaign_sub_name": {
        "zh": "",
        "en": ""
    },
    "campaign_inquiry": {
        "admin_id": ""
    },
    "campaign_type_id": "",
    "campaign_intr": {
        "zh": "",
        "en": ""
    },
    "campaign_venue": "",
    "campaign_prices": [],
    "campaign_quota": 1,
    "campaign_enrolment_method": 1,
    "campaign_target_audience": 1,
    "campaign_code": "",
    "campaign_status": 1,
    "is_publish": false,
    "campaign_id": "",
    "campaign_type_id": ""
};

export const initalCampaignBasicInfoPrice = {
    "price": 0,
    "Label": {
        "zh": "",
        "en": ""
    },
    "audience_id": ""
}

export const initalCampaignFiles = {
    "campaign_id": "",
    "campaign_medias": [],
    "campaign_icon": ""
};

export const initalCampaignMedia = {
    "type": "",
    "url": ""
};

const initialState = {
    campaignList: [],
    campaignEditMode: MODE_UNKNOWN,
    campaignEditData: {},
    editBasic: initialCampaignBasicInfo,
    editFiles: initalCampaignFiles
};

export const searchCampaigns = (sendData, campaignList, callback) => async (dispatch) => {
    //client side search campaign
    let cat = sendData.cat;
    let keyword = sendData.keyword

    let filterList = [];
    if (!keyword) {
        filterList = campaignList;
    } else {
        filterList = campaignList.filter((campaign) => {
            switch (cat) {
                case "Name":
                    return campaign.campaign_name.toLowerCase().includes(keyword.toLowerCase());
                case "Email":
                    return campaign.campaign_email.toLowerCase().includes(keyword.toLowerCase());
                case "Campaign ID":
                    return campaign.campaign_id.toLowerCase().includes(keyword.toLowerCase());
                case "Mobile":
                    return campaign.campaign_phone.toLowerCase().includes(keyword.toLowerCase());
                case "Card Ref":
                    return campaign.card_ref.toLowerCase().includes(keyword.toLowerCase());
                case "Account ID":
                    return campaign.campaign_id.toLowerCase().includes(keyword.toLowerCase());
                default:
                    return false;
            }
        });
    }

    let action = createAction("mCampaignReq/tknAware/searchCampaigns/fulfilled");
    let simulateResp = {
        "code": 1,
        "data": filterList
    };

    const thunkResult = await dispatch(action(simulateResp));


    //server side search campaign
    // const thunkResult = await dispatch(serchCampaignsReq(sendData));

    commonHandleError(thunkResult.payload, callback);
};

export const uploadCampaignImage = (file, callback) => async (dispatch) => {
    const postRes = await axios.post("https://membershipuat.maxisense.io/webapp/memportal/getuploadfileurl",
        {
            "usage": "campaign",
            "file": file.name
        });

    let data = postRes.data;

    if (1 == data?.code) {
        let putUrl = data.data.image_upload_url;
        let realImgUrl = data.data.image_url;
        let mimeType = file.type;
        let type = (mimeType.includes("image")) ? "image" : "video";
        let subName = file.name.substring(file.name.indexOf(".") + ".".length);

        let xhr = new XMLHttpRequest();
        xhr.open("PUT", putUrl, true);
        xhr.setRequestHeader("Content-Type", type + "/" + subName);
        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    callback(realImgUrl, "");
                } else {
                    callback(null, "upload failed:,", xhr.status, xhr.responseText);
                }
            }
        };

        xhr.send(file);
    } else {
        if (!data) {
            callback(null, "upload failed: no data");
        }
        else {
            callback(null, "upload failed, code:" + data.code + " message:" + data.message_en);
        }
    }
};

export const uploadCampaignImageBunch = (fileObj, callback) => async (dispatch) => {
    const postRes = await axios.post("https://membershipuat.maxisense.io/webapp/memportal/getuploadfileurl",
        {
            "usage": "campaign",
            "file": fileObj.file.name
        });

    let data = postRes.data;

    if (1 == data?.code) {
        let putUrl = data.data.image_upload_url;
        let realImgUrl = data.data.image_url;
        let mimeType = fileObj.file.type;
        let type = (mimeType.includes("image")) ? "image" : "video";
        let subName = fileObj.file.name.substring(fileObj.file.name.indexOf(".") + ".".length);

        fileObj.type = type;

        let xhr = new XMLHttpRequest();
        xhr.open("PUT", putUrl, true);
        xhr.setRequestHeader("Content-Type", type + "/" + subName);
        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    fileObj.fileSrcUrl = realImgUrl;
                    callback(fileObj, "upload success");
                } else {
                    callback(null, "upload failed:,", xhr.status, xhr.responseText);
                }
            }
        };

        xhr.send(fileObj.file);
    } else {
        if (!data) {
            callback(null, "upload failed: no data");
        }
        else {
            callback(null, "upload failed, code:" + data.code + " message:" + data.message_en);
        }
    }
};

export const getCampaignDetail = (campaignId, callback) => async (dispatch) => {
    let thunkResult;
    if (!campaignId) {
        let action = createAction("mCampaignReq/tknAware/getCampaignDetail/fulfilled");
        let simulateResp = {
            "code": 1,
            "data": {}
        };

        thunkResult = await dispatch(action(simulateResp));
    } else {
        let sendData = {
            "campaign_id": campaignId
        };

        thunkResult = await dispatch(getCampaignDetailReq(sendData));
    }

    commonHandleError(thunkResult.payload, callback);
};


export const getCampaigns = () => async (dispatch) => {
    await dispatch(getCampaignsReq());
}

export const getInterestList = () => async (dispatch) => {
    await dispatch(getInterestListReq());
};

export const getMobileList = (sendData) => async (dispatch) => {
    await dispatch(getMobileListReq(sendData));
};

export const createCampaign = (sendData, callback) => async (dispatch) => {
    const thunkResult = await dispatch(createCampaignReq(sendData));
    commonHandleError(thunkResult.payload, callback);
};

export const updateCampaign = (sendData, callback) => async (dispatch) => {
    const thunkResult = await dispatch(updateCampaignReq(sendData));
    commonHandleError(thunkResult.payload, callback);
};

export const deleteCampaign = (sendData, callback) => async (dispatch) => {
    const thunkResult = await dispatch(deleteCampaignReq(sendData));
    commonHandleError(thunkResult.payload, callback);
};

export const updateInterests = (sendData, callback) => async (dispatch) => {
    const thunkResult = await dispatch(updateInterestsReq(sendData));
    commonHandleError(thunkResult.payload, callback);
};

export const updatePwd = (sendData, callback) => async (dispatch) => {
    const thunkResult = await dispatch(updatePwdReq(sendData));
    commonHandleError(thunkResult.payload, callback);
};

export const updateInstructor = (sendData, callback) => async (dispatch) => {
    const thunkResult = await dispatch(updateInstructorReq(sendData));
    commonHandleError(thunkResult.payload, callback);
};

export const updateVolumnteer = (sendData, callback) => async (dispatch) => {
    const thunkResult = await dispatch(updateVolumnteerReq(sendData));
    commonHandleError(thunkResult.payload, callback);
};

export const updateMediaFiles = (sendData, callback) => async (dispatch) => {
    const thunkResult = await dispatch(updateMediaFilesReq(sendData));
    commonHandleError(thunkResult.payload, callback);
};

const getCampaignsReq = createAsyncThunk("mCampaignReq/tknAware/getCampaigns", async () => {
    let res = await axios.get("https://membershipuat.maxisense.io/webapp/memportal/getcampaignlist");
    return res.data;
});

//TODO peteryeh search campaign is not ready
const serchCampaignsReq = createAsyncThunk("mCampaignReq/tknAware/searchCampaigns", async (data) => {
    let res = await axios.post("https://membershipuat.maxisense.io/webapp/memportal/searchCampaigns", data);
    return res.data;
});

const createCampaignReq = createAsyncThunk("mCampaignReq/tknAware/createCampaign", async (data) => {
    let res = await axios.post("https://membershipuat.maxisense.io/webapp/memportal/createcampaign", data);
    // const res = await axios.post("http://localhost:3007/createCampaign", data);
    return res.data;
});

const updateCampaignReq = createAsyncThunk("mCampaignReq/tknAware/updateCampaign", async (data) => {
    let res = await axios.post("https://membershipuat.maxisense.io/webapp/memportal/updatecampaignbasic", data);
    // const res = await axios.post("http://localhost:3007/updateCampaign", data);
    return res.data;
});

const updateMediaFilesReq = createAsyncThunk("mCampaignReq/tknAware/updateMediaFiles", async (data) => {
    let res = await axios.post("https://membershipuat.maxisense.io/webapp/memportal/updatefileupload", data);
    return res.data;
});

const getCampaignDetailReq = createAsyncThunk("mCampaignReq/tknAware/getCampaignDetail", async (data) => {
    let res = await axios.post("https://membershipuat.maxisense.io/webapp/memportal/getcampaigndetail", data);
    return res.data;
});

const getInterestListReq = createAsyncThunk("mCampaignReq/tknAware/getInterestList", async () => {
    let res = await axios.get("https://membershipuat.maxisense.io/webapp/memportal/getinterestlist");
    return res.data;
});

const getMobileListReq = createAsyncThunk("mCampaignReq/tknAware/getMobileList", async (data) => {
    let res = await axios.post("https://membershipuat.maxisense.io/webapp/memportal/getcampaignmobilelist", data);
    return res.data;
});

const updateInterestsReq = createAsyncThunk("mCampaignReq/tknAware/updateInterests", async (data) => {
    let res = await axios.post("https://membershipuat.maxisense.io/webapp/memportal/updatecampaigninterest", data);
    return res.data;
});

const deleteCampaignReq = createAsyncThunk("mCampaignReq/tknAware/deleteCampaign", async (data) => {
    let res = await axios.post("https://membershipuat.maxisense.io/webapp/memportal/deletecampaign", data);
    return res.data;
});

const updateInstructorReq = createAsyncThunk("mCampaignReq/tknAware/updateInstructor", async (data) => {
    let res = await axios.post("https://membershipuat.maxisense.io/webapp/memportal/updatecampaigninstructor", data);
    return res.data;
});

const updatePwdReq = createAsyncThunk("mCampaignReq/tknAware/updatePwd", async (data) => {
    let res = await axios.post("https://membershipuat.maxisense.io/webapp/memportal/updatecampaignpassword", data);
    return res.data;
});

const updateVolumnteerReq = createAsyncThunk("mCampaignReq/tknAware/updateVolumnteer", async (data) => {
    let res = await axios.post("https://membershipuat.maxisense.io/webapp/memportal/updatecampaignvolunteer", data);
    return res.data;
});

const commonHandleError = (data, callback) => {
    if (callback) {
        let baseErrorMsgObj = {
            message: "系統發生問題, 請稍後再試",
            message_en: "An unexpected error occurred. Please try again."
        }

        if (1 == data?.code) {
            callback(true, data);
        } else {
            callback(false, (data) ? (data) : (baseErrorMsgObj))
        }
    }
};

const calculateAge = (dob) => {
    if (!dob) return "";

    const birthDate = new Date(dob);
    const today = new Date();

    let years = today.getFullYear() - birthDate.getFullYear();

    const isBeforeBirthdayThisYear =
        today.getMonth() < birthDate.getMonth() ||
        (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate());

    if (isBeforeBirthdayThisYear) {
        years -= 1;
    }

    return (`${years} ${years !== 1 ? '' : ''}`);
};

const setValByPath = (obj, path, value) => {
    let keys = path.split(/\.|\|/g); // Allow split . or |
    let lastKey = keys.pop(); // Get the last key
    let target = obj; // Reference to the original object

    // Traverse the object to the second last key
    for (let key of keys) {
        if (!(key in target) || typeof target[key] !== "object") {
            target[key] = {}; // Create an object if path is missing
        }
        if (/^[0-9]+$/.test(key)) {
            target = target[parseInt(key)] // Move deeper in array index
        } else {
            target = target[key]; // Move deeper in object
        }
    }

    if (/^[0-9]+$/.test(lastKey)) {
        lastKey = parseInt(lastKey);// last key is array index
    }

    target[lastKey] = value; // Set the final value
}

const assignFromBase = (base, dta) => {
    const result = Object.keys(base).reduce((acc, key) => {
        if (dta.hasOwnProperty(key)) {
            acc[key] = dta[key]; // 只更新 base 中已存在的 key
        }
        return acc;
    }, { ...base });

    return result;
}

const rdxCampaignSlice = createSlice({
    initialState,
    name: "rdxCampaign",
    extraReducers: (builder) => {
        builder
            .addCase(getCampaignsReq.fulfilled, (state, actions) => {
                let data = actions.payload;
                if (data.code === 1) {
                    state.campaignList = data.data;
                    state.filterCampaignList = data.data;
                }
            })
            .addCase(serchCampaignsReq.fulfilled, (state, actions) => {
                let data = actions.payload;
                if (data.code === 1) {
                    state.filterCampaignList = data.data;
                }
            })
            .addCase(getCampaignDetailReq.fulfilled, (state, actions) => {
                let data = actions.payload;
                if (data.code === 1) {
                    let origCampaignData = data.data;

                    state.campaignEditData = origCampaignData;
                    if (!data.data.campaign_id) {
                        state.campaignEditMode = MODE_ADD;
                    } else {
                        state.campaignEditMode = MODE_EDIT;
                    }
                }
            })
            .addCase(getInterestListReq.fulfilled, (state, actions) => {
                let data = actions.payload;
                if (data.code === 1) {
                    state.interestList = data.data;
                }
            })
            .addCase(getMobileListReq.fulfilled, (state, actions) => {
                let data = actions.payload;
                if (data.code === 1) {
                    state.mobileList = data.data;
                }
            });
    },
    reducers: {
        setEditCampaignData(state, actions) {

        },
        clearEditCampaign(state) {
            state.campaignEditMode = MODE_UNKNOWN;
            state.campaignEditData = {};
            state.editBasic = initialCampaignBasicInfo;
        },
        resetAllCampaignPageData(state) {
            state = initialState;
        },
        initCampaignSimpleData(state, actions) {
            let { data, projection, type } = actions.payload;

            if ("list" == type) {
                let resultData = (data) ? data : [];
                state[projection] = resultData;
            } else if ("obj" == type) {
                let base = {};
                switch (projection) {
                    case "editBasic": base = initialCampaignBasicInfo; break;
                    case "editFiles": base = initalCampaignFiles; break;
                }

                let obj = assignFromBase(base, data);
                state[projection] = obj;
            }
        },
        setCampaignSimpleData(state, actions) {
            let { data, projection, path } = actions.payload;

            if (Array.isArray(data) && !path) {
                state[projection] = data;
            } else {
                let formatPath;
                if (path.indexOf(projection) == 0) {
                    let headStr = projection + ".";
                    formatPath = path.substring(headStr.length);
                } else {
                    formatPath = path;
                }
                setValByPath(state[projection], formatPath, data);
            }
        },
        setCampaignSimpleDataToCampaignData(state, actions) {
            let { data, projection, base } = actions.payload;

            if (Array.isArray(data)) {
                // console.log("list update:", projection, data);
                state.campaignEditData[projection] = data;
            } else {
                let obj = Object.assign({}, base, data);
                if ("self" == projection) {
                    // console.log("self update:", projection, obj);
                    state.campaignEditData = obj;
                } else {
                    // console.log("partial update:", projection, obj);
                    state.campaignEditData[projection] = obj;
                }
            }
        }
    }
});

export const {
    setEditCampaignData, clearEditCampaign, resetAllCampaignPageData,
    initCampaignSimpleData, setCampaignSimpleData, setCampaignSimpleDataToCampaignData } = rdxCampaignSlice.actions;

export const rdxCampaignReducer = rdxCampaignSlice.reducer;