import React from "react";
import { Container, Row, Col, Card, Table } from "react-bootstrap";
import './membercss/MemberInfo.css';
import useTranslations from "../transalation/TranslationUtils";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getMobileList } from "app/redux/slices/rdxMemberSlice";
import { useEffect } from "react";
import { Button } from "@mui/material";
function RecentDevices() {
    const dispatch = useDispatch();

    const memberEditData = useSelector((state) => state.rdxMember?.memberEditData || {});
    const mobileList = useSelector((state) => state.rdxMember?.mobileList || {});

    const { recentDevices, device, location, recentActivity, mFunc, deletes } = useTranslations();

    useEffect(() => {
        dispatch(getMobileList({ "account_id": memberEditData.account_id }));
    }, []);

    const handleRemoveDevice = (device) => {
        alert("API not provided!");
    }

    function formatDate(input, format = "date") {
        let date;

        if (typeof input === "string") {
            date = new Date(input);
        } else if (typeof input === "number") {
            date = new Date(input);
        } else if (Array.isArray(input) && input.length === 1 && typeof input[0] === "number") {
            date = new Date(input[0]);
        } else if (!input) {
            return "";
        } else {
            return "Invalid";
        }

        // Ensure the Date object is valid
        if (isNaN(date.getTime())) {
            return "Invalid";
        }

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        if (format === "date") {
            return `${year}-${month}-${day}`;
        } else if (format === "datetime") {
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');
            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        } else {
            return "Invalid";
        }
    }

    return (
        <Container fluid>
            <Row>
                <Col>
                    <Card className="custom-shadow" style={{ border: 'none' }} >
                        <Card.Body>
                            <h6 className="titlename">{recentDevices()}</h6>
                            <Table bordered={false} responsive>
                                <thead >
                                    <tr >
                                        <th className="text-center table-header" style={{ backgroundColor: '#E9E9E9' }} >{device()}</th>
                                        {/* <th className="text-center table-header" style={{ backgroundColor: '#E9E9E9' }} >{location()}</th> */}
                                        <th className="text-center table-header" style={{ backgroundColor: '#E9E9E9' }}>{recentActivity()}</th>
                                        <th className="text-center table-header" style={{ backgroundColor: '#E9E9E9' }}>{mFunc()}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {mobileList.length === 0 ? (
                                        <tr>
                                            <td style={{ color: '#9B9B9B' }} colSpan="3" className="text-center">
                                                No data
                                            </td>
                                        </tr>
                                    ) : (
                                        mobileList.map((device, index) => (
                                            <tr key={index}>
                                                <td className="text-center">{device.mobile_name}</td>
                                                {/* <td className="text-center">{device.location}</td> */}
                                                <td className="text-center">{formatDate(device.last_action_date, "date")}</td>
                                                <td className="text-center">
                                                    <Button variant="contained" color="error"
                                                        onClick={() => handleRemoveDevice(device)}>
                                                        {deletes()}
                                                    </Button>
                                                </td>

                                            </tr>
                                        ))
                                    )}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default RecentDevices;
