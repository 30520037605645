import React, { useState } from "react";
import { Container, Row, Col, Card, Button, Form, Modal, Alert } from "react-bootstrap";
import { Add } from '@mui/icons-material';
import Avatars from './Avatars';
import "bootstrap/dist/css/bootstrap.min.css";
import "./membercss/MemberInfo.css";
import useTranslations from '../transalation/TranslationUtils';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { initSimpleData, setSimpleData, setSimpleDataToMemberData, updateMember } from "app/redux/slices/rdxMemberSlice";
import { useEffect } from "react";
import { Chip, Stack } from "@mui/material";

function MembershipDetails() {
    const { memberDetails, addNewRelationship, savechaneg,
        showErrMsg, membershipLevel, membershipIdentity,
        membershipStatus, opsCenter, i18nObj,
        mFreeUser, mPaidUser, mActive, mSuspend, mDeceased
    } = useTranslations();
    const [showModal, setShowModal] = useState(false);
    const [selectedTags, setSelectedTags] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);

    const dispatch = useDispatch();
    const basicInfoFormMap = useSelector((state) => state.rdxMember?.basicInfoFormMap || {});
    const audienceIdMap = useSelector((state) => state.rdxMain?.audienceIdMap || {});
    const audienceList = useSelector((state) => state.rdxMain?.memberIdentOptions || []);
    const memberEditData = useSelector((state) => state.rdxMember?.memberEditData || {});
    const editMembership = useSelector((state) => state.rdxMember?.editMembership || {});

    useEffect(() => {
        let data = {
            data: { ...memberEditData },
            projection: "editMembership",
            type: "obj"
        };
        dispatch(initSimpleData(data));
    }, []);

    const handleInputChange = (e, path) => {
        const { value } = e.target;

        let data = {
            data: value,
            projection: "editMembership",
            path: path
        }
        dispatch(setSimpleData(data));
    };

    const handleSave = async (event) => {
        event.preventDefault(); // Prevent default form submission behavior

        let sendData = {
            "account_id": memberEditData.account_id
        };

        for (const key in basicInfoFormMap) {
            let formItem = basicInfoFormMap[key];
            if (!formItem.ignore) {
                sendData[key] = basicInfoFormMap[key].val;
            }
        }

        sendData.account_level = editMembership.account_level;
        sendData.account_identity = editMembership.account_identity;
        sendData.account_status = editMembership.account_status;

        dispatch(updateMember(sendData, (isSuccess, data) => {
            if (isSuccess) {
                let rData = {
                    data: editMembership,
                    projection: "self",
                    base: memberEditData
                };
                dispatch(setSimpleDataToMemberData(rData));

                setSuccessMessage("Basic information saved successfully!");
                setError(null);
            } else {
                setSuccessMessage(null);
                setError(showErrMsg(data));
            }
        }));
    };

    const handleAddTag = () => {
        // if (selectedTags.length > 0) {
        //     const newTags = selectedTags.filter(tag => !tags.includes(tag));
        //     setTags([...tags, ...newTags]);
        //     setShowModal(false);
        // } else {
        //     setErrorMessage("Please select at least one tag.");
        // }
    };

    const removeTag = (tag) => {
        // setTags(tags.filter((t) => t !== tag));
    };

    const handleTagChange = (e) => {
        const { value, checked } = e.target;
        if (checked) {
            setSelectedTags([...selectedTags, value]);
        } else {
            setSelectedTags(selectedTags.filter((tag) => tag !== value));
        }
    };

    return (
        <Container fluid>
            <Row>
                <Col md={12}>
                    <Card className="mt-0 border-0" style={{ position: "relative" }}>
                        <Card.Body>
                            <Avatars initAvatar={memberEditData["account_avatar"]} />
                            <br />
                            <Card className="p-3 m-3">
                                <Form onSubmit={handleSave}>
                                    <Row>
                                        <h5 className="basicinfo">{memberDetails()}</h5>
                                        <Col md={6}>
                                            <Form.Group controlId="account_level" className="floating-label-group">
                                                <Form.Select
                                                    name="account_level"
                                                    value={editMembership.account_level}
                                                    onChange={(event) => handleInputChange(event, "editMembership.account_level")}
                                                    className="floating-input">
                                                    <option key="free" value="free">{mFreeUser()}</option>
                                                    <option key="paid" value="paid">{mPaidUser()}</option>
                                                </Form.Select>
                                                <Form.Label className="floating-label basicinfolabel">{membershipLevel()}</Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group controlId="account_identity" className="floating-label-group">
                                                <Form.Select
                                                    name="account_identity"
                                                    value={editMembership.account_identity}
                                                    onChange={(event) => handleInputChange(event, "editMembership.account_identity")}
                                                    className="floating-input">
                                                    {audienceList.map((item) => (
                                                        <option key={item.audience_id} value={item.audience_id}>{i18nObj(item.audience_name)}</option>
                                                    ))}
                                                </Form.Select>
                                                <Form.Label className="floating-label basicinfolabel">{membershipIdentity()}</Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group controlId="account_status" className="floating-label-group">
                                                <Form.Select
                                                    name="account_status"
                                                    value={editMembership.account_status}
                                                    onChange={(event) => handleInputChange(event, "editMembership.account_status")}
                                                    className="floating-input">
                                                    <option key="active" value="active">{mActive()}</option>
                                                    <option key="suspend" value="suspend">{mSuspend()}</option>
                                                    <option key="deceased" value="deceased">{mDeceased()}</option>
                                                </Form.Select>
                                                <Form.Label className="floating-label basicinfolabel">{membershipStatus()}</Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group controlId="account_centre" className="floating-label-group">
                                                <Form.Control
                                                    disabled={true}
                                                    type="text"
                                                    name="account_centre"
                                                    value={i18nObj(editMembership.account_centre)}
                                                    className="floating-input basicinfoinput"
                                                />
                                                <Form.Label className="floating-label basicinfolabel">{opsCenter()}</Form.Label>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    {/* <Row className="mt-3">
                                        <Col md={1}>
                                            <Button
                                                style={{
                                                    backgroundColor: '#1976D2',
                                                    border: 'none',
                                                    height: '24px',
                                                    width: '24px',
                                                    padding: '0'
                                                }}
                                                onClick={() => setShowModal(true)}
                                            >
                                                <Add style={{ color: '#fff', fontSize: '16px' }} />
                                            </Button>
                                        </Col>
                                    </Row> */}
                                    <br />
                                    <Stack direction="row" spacing={1}>
                                        {editMembership.tags?.map((tag, index) => (
                                            <Chip key={index} label={i18nObj(audienceIdMap[tag.tag_id]?.audience_name)} color="primary" />
                                        ))}
                                    </Stack>

                                    <Row className="mt-3">
                                        <Col className="mt-4">
                                            <Button variant="primary" type="submit" className="me-2 instructorphoto">
                                                {savechaneg()}
                                            </Button>
                                            {/* <Button variant="outline-primary" className="canslebtn">{cancel()}</Button> */}
                                        </Col>
                                    </Row>

                                    {error && <p className="text-danger mt-3">{error}</p>}
                                    {successMessage && <p className="text-success mt-3">{successMessage}</p>}
                                </Form>
                            </Card>

                            {/* <Card className="ps-3 m-3" style={{ height: "200px" }}>
                                <Row className="mt-4">
                                    <h5 className="basicinfo ">Member Relationship</h5>
                                </Row>
                                <Row className="h-100">
                                    <Col className="d-flex align-items-center justify-content-center">
                                        <Button variant="primary" type="submit" className="instructorphoto">
                                            {addNewRelationship()}
                                        </Button>
                                    </Col>
                                </Row>
                            </Card> */}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                <Modal.Body>
                    <Form>
                        <h4 className="assigntohead ms-2">Add Tag(s)</h4>
                        {errorMessage && (
                            <Alert variant="danger">
                                {errorMessage}
                            </Alert>
                        )}
                        <Form.Group controlId="formStatus" className="mt-3 ms-3">
                            {["Instructor", "Caregiver", "Volunteer"].map((tag, index) => (
                                <Form.Check
                                    key={index}
                                    type="checkbox"
                                    label={tag}
                                    value={tag}
                                    onChange={handleTagChange}
                                    className="assigntocheck mb-3"
                                />
                            ))}
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="" onClick={() => setShowModal(false)} className="me-2 groupclear">Cancel</Button>
                    <Button variant="" className="groupsearch" onClick={handleAddTag}>Save</Button>
                </Modal.Footer>
            </Modal>

            {/* <style jsx>{`
                .tag {
                    background-color: #e0f7ff;
                    color: #007bff;
                    display: inline-flex;
                    align-items: center;
                }
                .remove-btn {
                    background: none;
                    border: none;
                    color: #007bff;
                    cursor: pointer;
                    font-size: 14px;
                }
                .remove-btn:hover {
                    color: #0056b3;
                }
                `}</style> */}
        </Container>
    );
}

export default MembershipDetails;
